// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-book-event-jsx": () => import("./../src/pages/book-event.jsx" /* webpackChunkName: "component---src-pages-book-event-jsx" */),
  "component---src-pages-gallery-jsx": () => import("./../src/pages/gallery.jsx" /* webpackChunkName: "component---src-pages-gallery-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-menu-jsx": () => import("./../src/pages/menu.jsx" /* webpackChunkName: "component---src-pages-menu-jsx" */),
  "component---src-pages-success-jsx": () => import("./../src/pages/success.jsx" /* webpackChunkName: "component---src-pages-success-jsx" */)
}

